import React from "react";
import { styled } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Card } from "@mui/material";

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
}));

export function PluggableDebugDoc() {
    return (
        <Box sx={{ padding:2 }}>
            <Typography variant="h5" align="center">PR Debug Guide</Typography>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Heading>Getting Started</Heading>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography component="div">
                        <div>Click on a <b style={{ color: 'red' }}>red marker</b> to zoom in/out of a broken section.</div>
                        <div>When zoomed in, you will see trace points.</div>
                        <div style={{marginTop:'0.5em'}}>Adjust the trace points as needed. 
                            <div>NOTE: you may need to zoom to ~100-50m for a more accurate road network.</div>
                        </div>
                        <div style={{marginTop:'0.5em'}}>After adjusting a trace point, a new <b>Test Import</b> button will appear.</div>
                        <div style={{marginTop:'0.5em'}}> Click the <b>Test Import</b> button to test the HERE import for the new geometry points.</div>
                    </Typography>
                </AccordionDetails>

            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Heading>Test Import Results</Heading>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography component="div">
                        <div>If the import is successful, a <b style={{ color: 'green' }}>green polyline</b> will appear across the section.</div>
                        <div>If the import fails, a <b>notices section</b> will appear below the button with further details.</div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Heading>Test Patch</Heading>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography component="div">
                        <div>If the import is successful, a new orange<b style={{ color: '#ed6c02' }}> Test Patch</b> button will appear.</div>
                        <div> Click the <b>Test Patch</b> button to test the patch for the changed points.</div> 
                   </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Heading>Restarting</Heading>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography component="div">
                        <div>After any changes are made, you may click the <b>Clear Changes</b> button to return to the inital data state.</div>
                        <ul>
                            <li>All moved points will be moved to their original positions.</li>
                            <li>All green polylines will be removed</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>

    )
}
