import { useEffect, useRef } from 'react';
import logo from '../resources/img/IA.svg';

export const IALoading = (props) => {
    const size = props.size || 0.8;
    const canvasRef = useRef(null);
    const imageRef = useRef(new Image());

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const image = imageRef.current;

        image.src = logo;

        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;

            let angle = 0;

            const drawFrame = () => {
                // Clear canvas
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                // Draw the scaled logo
                const scale = size;
                const imgWidth = canvas.width * scale;
                const imgHeight = canvas.height * scale;
                const imgX = (canvas.width - imgWidth) / 2;
                const imgY = (canvas.height - imgHeight) / 2;
                ctx.drawImage(image, imgX, imgY, imgWidth, imgHeight);

                // Calculate white pixel position
                const radius = Math.min(canvas.width, canvas.height) / 2 - 15;
                const x = canvas.width / 2 + radius * Math.cos(angle);
                const y = canvas.height / 2 + radius * Math.sin(angle);

                // Draw the white pixel
                ctx.fillStyle = "white";
                ctx.beginPath();
                ctx.arc(x, y, 5, 0, 2 * Math.PI);
                ctx.fill();
                angle += 0.05;
                requestAnimationFrame(drawFrame);
            };
            drawFrame();
        };

        return () => { image.onload = null };
    }, []);

    return <canvas ref={canvasRef} />;
};