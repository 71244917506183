
/* This gets around object comparison by directly examining
 * values and only returning modified values. This should
 * help out with patch requests.
 * This function handles arrays naively (in other words,
 * if it isn't exactly the same, it should add the entire
 * array).
 *
 * */
import _ from 'lodash'
import * as Sentry from "@sentry/browser";
const NodeRSA = require('node-rsa')

function selectModifiedValues(previous, current) {
  // if it's not an object, do a normal check
  if (typeof current !== 'object' || current === null || current === undefined || Array.isArray(current)) {
    if (Array.isArray(current)) {
      return (_.isEqual(_.sortBy(previous), _.sortBy(current)) ? undefined : current);
    }
    return (previous !== current ? current : undefined);
  }
  // if we have an object, we need to unpack it
  else {
    var payload = {};
    for (let [key, value] of Object.entries(current)) {
      if(key == "gcm_app" && current[key] == null) {
        current[key] = {gcm_push_notification_key: null}
      }
      if(previous === null || previous === undefined) {
        payload[key] = value
      }
      else if (previous[key] !== value) {
        let temp = selectModifiedValues(previous[key], current[key]);
        if (temp !== undefined) payload[key] = temp;
      }
    }
    return (Object.keys(payload).length === 0 ? undefined : payload);
  }
}

function stripAuthHeaders(response_object) {
  if (response_object && response_object.request_headers) {
    if (response_object.request_headers.hasOwnProperty('Authorization')) {
      response_object.request_headers.Authorization="[scrubbed]";
    }
    if (response_object.request_headers.hasOwnProperty('Eleos-Platform-Key')) {
      response_object.request_headers['Eleos-Platform-Key']="[scrubbed]";
    }
  }
}

function displayError(response) {
  var error_message = ""
  response.clone().json().then(data => {
    if (data.hasOwnProperty("errors")) {
      for (const error in data.errors) {
        if(Number.isInteger(error))  error_message += (data.errors[error] + "\n")
        else error_message += (error + ": " + data.errors[error] + "\n")
      }
      alert(error_message + ` (${response.status})`);
    }
  });
}


/*  
 *  Catch errors from the Data Provider and provide generic error messages for them, display them to the user.
 *  Depending on the resource, some of the statuses do not need to be rejected. They are acceptable.
 *  Rejecting them causes uncaught promises.
 *  By passing the resource, we can check for these acceptable responses and resolve the promise accordingly.
 *  This approach prevents unnecessary uncaught promise logs.
 */
const errorHandler = (response, resource) => {
  const { status, url } = response;
  //We want to make sure we reject with the resource and url. This way, errors in Sentry won't just be tossed in the same box.
  const createError = (message) => Promise.reject({ status, message, resource, url });
  switch (status) {
    case 500:
      return createError("500 Internal Server Error");
    case 404:
      return createError("404 Not Found");
    case 403:
      return createError("403 Forbidden");
    case 401:
      return createError("401 Unauthorized");
    case 400:
      displayError(response);
      return createError("400 Bad Request");
    default:
      return response;
  }
};

const handleEmpty = async (resource, type, status, body) => {
  const handleErrorMessage = async (errorKey) => {
    const message = body?.message || "An error occurred";
    localStorage.setItem(errorKey, message);
  };

  const passToSentry = () => {
    const sentryError = new Error(`${type} failed for ${resource}.`);
    sentryError.name = `${resource} Request Failed - [${status}]`;
    Sentry.setContext('request', { 'response_body': body });
    Sentry.captureException(sentryError, {
      tags: { resource, type, status }
    });
  }

  const is404 = status === 404;
  const isErrorStatus = [404, 400, 500].includes(status);

  const manyEmptyResponse = { data: [], total: 0 };
  const oneEmptyResponse = { data:  {id: []}}

  switch (resource) {
    case 'internal_permissions':
      if (is404) {
        await handleErrorMessage('permissionsListError');
        passToSentry();
        return [{}];
      }
      return null;
    case 'documents':
      if (isErrorStatus) {
        await handleErrorMessage('documentListError');
        passToSentry();
        return [];
      }
      return null;
    case 'changelog':
      let changelogRes = [{"before": "error", "after": "error", "superuser_email": "error retrieving changelog", "occurred_at": Date.now()}]
      if (status === 500) {
        passToSentry();
        return changelogRes;
      }
      return null;
    case 'audit_log':
      let auditRes = [{ "draft_approved_by": "error", "draft_created_by": "error", "path": "error retrieving audit log", "occurred_at": Date.now() }]
      if ([404, 500].includes(status)){
        passToSentry();
        return auditRes;
      }
      return null;
    case 'apps':
    case 'alt_dashboard_logos':
    case 'price_sheet_items':
    case 'stripe_charges':
    case 'account_contracts':
    case 'app_assets':
    case 'trip_routes':
    case 'trip_plans':
    case 'contract_structure_products':
      if (['GET_MANY', 'GET_MANY_REFERENCE', 'GET_LIST'].includes(type)){ passToSentry(); return is404 ? manyEmptyResponse : null }
      if(type === 'GET_ONE') { passToSentry(); return is404 ? oneEmptyResponse : null }
      return is404 ? [] : null;
    case 'scanning_users':
      if (['GET_MANY', 'GET_MANY_REFERENCE', 'GET_LIST'].includes(type)){ passToSentry(); return is404 ? {"data": [{ id: "empty" }], total: 0} : null }
      return is404 ? [] : null;
    default:
      return null;
  }
};

function regex(target, pattern) {
  var containsPattern = false;
  if(target.toLowerCase().includes(pattern.toLowerCase())){
    containsPattern = true;
  }
  return containsPattern;
}

// in general the filter will check if a resource matches against an exact string, based on some attribute
// unless you are trying to filter by the name/app_name in which case if there is no match try to match against the resources key or client_key
// most of the time you are trying to search for a name/key at the same time (ex: searching for clients)
function filterResources(resources, filters) {
  const filteredResources = [];
  for (const i in resources) {
    var passesFilter = true;
    Object.entries(filters).forEach(([field, value]) => {
      if (resources[i][field] && ((field === "name") || (field === "app_name") || (field === "code"))) { // if the filter is for a name, also check the key as well
        if (!regex(resources[i][field], value)) {
          if(resources[i]["key"]) {
            if(!regex(resources[i]["key"], value)) {
              passesFilter = false;
            } 
          }
          else if(resources[i]["client_key"]) {
            if(!regex(resources[i]["client_key"], value)) {
              passesFilter = false;
            } 
          }
          else {
            passesFilter = false;
          }
          return;
        }
      }
      else if (resources[i]["event"]){
        const event = resources[i].event;
        if(field === "event.path" && !regex(event.path, value)){
          passesFilter = false;
          return;
        }
        if(field === "event.params.key"){
          const paramsKey = event.params?.key;
          if (paramsKey !== value) {
            passesFilter = false;
            return;
          }
        }
      }
      else if (resources[i][field] !== value) {
        passesFilter = false;
        return;
      }
    });
    if (passesFilter) {
      filteredResources.push(resources[i]);
    }
  }
  return filteredResources;
}

function getBillingAccountByUUID(billingAccounts, uuid) {
  for (const i in billingAccounts) {
    if (billingAccounts[i].external_id === uuid) {
      return billingAccounts[i]
    }
  }
  return { name: "" }
}

const FEATURE_TABLE =
`000000000027233a         push       rbp                                         ; CODE XREF=__ZN3mpa16PermissionStringC2ERNSt3__112basic_stringIcNS1_11char_traitsIcEENS1_9allocatorIcEEEE+1067
000000000027233b         mov        rbp, rsp
000000000027233e         push       rbx
000000000027233f         sub        rsp, 0xc8
0000000000272346         mov        rbx, rdi
0000000000272349         add        rbx, 0x38
000000000027234d         mov        dword [rbp+var_C], 0x3
0000000000272354         lea        rsi, qword [rbp+var_C]                      ; argument #2 for method sub_210992
0000000000272358         mov        rdi, rbx                                    ; argument #1 for method sub_210992
000000000027235b         call       sub_210992                                  ; sub_210992
0000000000272360         lea        rsi, qword [aDriveNavigatio]                ; "Drive Navigation"
0000000000272367         mov        rdi, rax
000000000027236a         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
000000000027236f         mov        dword [rbp+var_10], 0x4
0000000000272376         lea        rsi, qword [rbp+var_10]                     ; argument #2 for method sub_210992
000000000027237a         mov        rdi, rbx                                    ; argument #1 for method sub_210992
000000000027237d         call       sub_210992                                  ; sub_210992
0000000000272382         lea        rsi, qword [aWalkNavigation]                ; "Walk Navigation"
0000000000272389         mov        rdi, rax
000000000027238c         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272391         mov        dword [rbp+var_14], 0x6
0000000000272398         lea        rsi, qword [rbp+var_14]                     ; argument #2 for method sub_210992
000000000027239c         mov        rdi, rbx                                    ; argument #1 for method sub_210992
000000000027239f         call       sub_210992                                  ; sub_210992
00000000002723a4         lea        rsi, qword [aAr_10f5bba]                    ; "AR"
00000000002723ab         mov        rdi, rax
00000000002723ae         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002723b3         mov        dword [rbp+var_18], 0x7
00000000002723ba         lea        rsi, qword [rbp+var_18]                     ; argument #2 for method sub_210992
00000000002723be         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002723c1         call       sub_210992                                  ; sub_210992
00000000002723c6         lea        rsi, qword [a3dVenueMaps]                   ; "3D Venue Maps"
00000000002723cd         mov        rdi, rax
00000000002723d0         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002723d5         mov        dword [rbp+var_1C], 0xa
00000000002723dc         lea        rsi, qword [rbp+var_1C]                     ; argument #2 for method sub_210992
00000000002723e0         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002723e3         call       sub_210992                                  ; sub_210992
00000000002723e8         lea        rsi, qword [aTruckRouting]                  ; "Truck Routing"
00000000002723ef         mov        rdi, rax
00000000002723f2         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002723f7         mov        dword [rbp+var_20], 0xb
00000000002723fe         lea        rsi, qword [rbp+var_20]                     ; argument #2 for method sub_210992
0000000000272402         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272405         call       sub_210992                                  ; sub_210992
000000000027240a         lea        rsi, qword [aCustomLocation]                ; "Custom Location"
0000000000272411         mov        rdi, rax
0000000000272414         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272419         mov        dword [rbp+var_24], 0xc
0000000000272420         lea        rsi, qword [rbp+var_24]                     ; argument #2 for method sub_210992
0000000000272424         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272427         call       sub_210992                                  ; sub_210992
000000000027242c         lea        rsi, qword [aTimetableTrans]                ; "Timetable Transit"
0000000000272433         mov        rdi, rax
0000000000272436         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
000000000027243b         mov        dword [rbp+var_28], 0xd
0000000000272442         lea        rsi, qword [rbp+var_28]                     ; argument #2 for method sub_210992
0000000000272446         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272449         call       sub_210992                                  ; sub_210992
000000000027244e         lea        rsi, qword [aTruckRestricti]                ; "Truck Restrictions"
0000000000272455         mov        rdi, rax
0000000000272458         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
000000000027245d         mov        dword [rbp+var_2C], 0xe
0000000000272464         lea        rsi, qword [rbp+var_2C]                     ; argument #2 for method sub_210992
0000000000272468         mov        rdi, rbx                                    ; argument #1 for method sub_210992
000000000027246b         call       sub_210992                                  ; sub_210992
0000000000272470         lea        rsi, qword [aCongestionZone]                ; "Congestion Zones"
0000000000272477         mov        rdi, rax
000000000027247a         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
000000000027247f         mov        dword [rbp+var_30], 0x10
0000000000272486         lea        rsi, qword [rbp+var_30]                     ; argument #2 for method sub_210992
000000000027248a         mov        rdi, rbx                                    ; argument #1 for method sub_210992
000000000027248d         call       sub_210992                                  ; sub_210992
0000000000272492         lea        rsi, qword [aHistoricalSpee]                ; "Historical Speed Pattern"
0000000000272499         mov        rdi, rax
000000000027249c         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002724a1         mov        dword [rbp+var_34], 0xf
00000000002724a8         lea        rsi, qword [rbp+var_34]                     ; argument #2 for method sub_210992
00000000002724ac         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002724af         call       sub_210992                                  ; sub_210992
00000000002724b4         lea        rsi, qword [aFleetMap]                      ; "Fleet Map"
00000000002724bb         mov        rdi, rax
00000000002724be         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002724c3         mov        dword [rbp+var_38], 0x11
00000000002724ca         lea        rsi, qword [rbp+var_38]                     ; argument #2 for method sub_210992
00000000002724ce         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002724d1         call       sub_210992                                  ; sub_210992
00000000002724d6         lea        rsi, qword [aSatelliteImage]                ; "Satellite Imagery"
00000000002724dd         mov        rdi, rax
00000000002724e0         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002724e5         mov        dword [rbp+var_3C], 0x12
00000000002724ec         lea        rsi, qword [rbp+var_3C]                     ; argument #2 for method sub_210992
00000000002724f0         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002724f3         call       sub_210992                                  ; sub_210992
00000000002724f8         lea        rsi, qword [a3dLandmarks]                   ; "3D Landmarks"
00000000002724ff         mov        rdi, rax
0000000000272502         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272507         mov        dword [rbp+var_40], 0x13
000000000027250e         lea        rsi, qword [rbp+var_40]                     ; argument #2 for method sub_210992
0000000000272512         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272515         call       sub_210992                                  ; sub_210992
000000000027251a         lea        rsi, qword [aBuildingIntera]                ; "Building Interaction"
0000000000272521         mov        rdi, rax
0000000000272524         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272529         mov        dword [rbp+var_44], 0x14
0000000000272530         lea        rsi, qword [rbp+var_44]                     ; argument #2 for method sub_210992
0000000000272534         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272537         call       sub_210992                                  ; sub_210992
000000000027253c         lea        rsi, qword [aVenueMaps]                     ; "Venue Maps"
0000000000272543         mov        rdi, rax
0000000000272546         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
000000000027254b         mov        dword [rbp+var_48], 0x15
0000000000272552         lea        rsi, qword [rbp+var_48]                     ; argument #2 for method sub_210992
0000000000272556         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272559         call       sub_210992                                  ; sub_210992
000000000027255e         lea        rsi, qword [aOfflineMapsWit]                ; "Offline Maps With ODML"
0000000000272565         mov        rdi, rax
0000000000272568         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
000000000027256d         mov        dword [rbp+var_4C], 0x16
0000000000272574         lea        rsi, qword [rbp+var_4C]                     ; argument #2 for method sub_210992
0000000000272578         mov        rdi, rbx                                    ; argument #1 for method sub_210992
000000000027257b         call       sub_210992                                  ; sub_210992
0000000000272580         lea        rsi, qword [aStreetLevel]                   ; "Street Level"
0000000000272587         mov        rdi, rax
000000000027258a         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
000000000027258f         mov        dword [rbp+var_50], 0x17
0000000000272596         lea        rsi, qword [rbp+var_50]                     ; argument #2 for method sub_210992
000000000027259a         mov        rdi, rbx                                    ; argument #1 for method sub_210992
000000000027259d         call       sub_210992                                  ; sub_210992
00000000002725a2         lea        rsi, qword [aTransitRouting]                ; "Transit routing"
00000000002725a9         mov        rdi, rax
00000000002725ac         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002725b1         mov        dword [rbp+var_54], 0x18
00000000002725b8         lea        rsi, qword [rbp+var_54]                     ; argument #2 for method sub_210992
00000000002725bc         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002725bf         call       sub_210992                                  ; sub_210992
00000000002725c4         lea        rsi, qword [aOfflineCarRout]                ; "Offline Car Routing "
00000000002725cb         mov        rdi, rax
00000000002725ce         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002725d3         mov        dword [rbp+var_58], 0x19
00000000002725da         lea        rsi, qword [rbp+var_58]                     ; argument #2 for method sub_210992
00000000002725de         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002725e1         call       sub_210992                                  ; sub_210992
00000000002725e6         lea        rsi, qword [aOfflinePedestr]                ; "Offline Pedestrian Routing"
00000000002725ed         mov        rdi, rax
00000000002725f0         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002725f5         mov        dword [rbp+var_5C], 0x1a
00000000002725fc         lea        rsi, qword [rbp+var_5C]                     ; argument #2 for method sub_210992
0000000000272600         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272603         call       sub_210992                                  ; sub_210992
0000000000272608         lea        rsi, qword [aOfflineTransit]                ; "Offline Transit Routing"
000000000027260f         mov        rdi, rax
0000000000272612         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272617         mov        dword [rbp+var_60], 0x1b
000000000027261e         lea        rsi, qword [rbp+var_60]                     ; argument #2 for method sub_210992
0000000000272622         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272625         call       sub_210992                                  ; sub_210992
000000000027262a         lea        rsi, qword [aTrafficEnabled]                ; "Traffic Enabled Car Routing"
0000000000272631         mov        rdi, rax
0000000000272634         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272639         mov        dword [rbp+var_64], 0x1c
0000000000272640         lea        rsi, qword [rbp+var_64]                     ; argument #2 for method sub_210992
0000000000272644         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272647         call       sub_210992                                  ; sub_210992
000000000027264c         lea        rsi, qword [aOfflineGeocodi]                ; "Offline Geocoding"
0000000000272653         mov        rdi, rax
0000000000272656         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
000000000027265b         mov        dword [rbp+var_68], 0x1d
0000000000272662         lea        rsi, qword [rbp+var_68]                     ; argument #2 for method sub_210992
0000000000272666         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272669         call       sub_210992                                  ; sub_210992
000000000027266e         lea        rsi, qword [aOfflineReverse]                ; "Offline Reverse Geocoding"
0000000000272675         mov        rdi, rax
0000000000272678         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
000000000027267d         mov        dword [rbp+var_6C], 0x1e
0000000000272684         lea        rsi, qword [rbp+var_6C]                     ; argument #2 for method sub_210992
0000000000272688         mov        rdi, rbx                                    ; argument #1 for method sub_210992
000000000027268b         call       sub_210992                                  ; sub_210992
0000000000272690         lea        rsi, qword [aOfflinePlaceSe]                ; "Offline Place Search"
0000000000272697         mov        rdi, rax
000000000027269a         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
000000000027269f         mov        dword [rbp+var_70], 0x1f
00000000002726a6         lea        rsi, qword [rbp+var_70]                     ; argument #2 for method sub_210992
00000000002726aa         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002726ad         call       sub_210992                                  ; sub_210992
00000000002726b2         lea        rsi, qword [aOfflineCategor]                ; "Offline Category Search"
00000000002726b9         mov        rdi, rax
00000000002726bc         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002726c1         mov        dword [rbp+var_74], 0x20
00000000002726c8         lea        rsi, qword [rbp+var_74]                     ; argument #2 for method sub_210992
00000000002726cc         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002726cf         call       sub_210992                                  ; sub_210992
00000000002726d4         lea        rsi, qword [aAdvancedDriveN]                ; "Advanced Drive Navigation"
00000000002726db         mov        rdi, rax
00000000002726de         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002726e3         mov        dword [rbp+var_78], 0x21
00000000002726ea         lea        rsi, qword [rbp+var_78]                     ; argument #2 for method sub_210992
00000000002726ee         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002726f1         call       sub_210992                                  ; sub_210992
00000000002726f6         lea        rsi, qword [aTrafficInforma]                ; "Traffic Information"
00000000002726fd         mov        rdi, rax
0000000000272700         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272705         mov        dword [rbp+var_7C], 0x22
000000000027270c         lea        rsi, qword [rbp+var_7C]                     ; argument #2 for method sub_210992
0000000000272710         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272713         call       sub_210992                                  ; sub_210992
0000000000272718         lea        rsi, qword [aTrafficOnMap]                  ; "Traffic On Map"
000000000027271f         mov        rdi, rax
0000000000272722         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272727         mov        dword [rbp+var_80], 0x23
000000000027272e         lea        rsi, qword [rbp+var_80]                     ; argument #2 for method sub_210992
0000000000272732         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272735         call       sub_210992                                  ; sub_210992
000000000027273a         lea        rsi, qword [aOnlinePosition]                ; "Online Positioning"
0000000000272741         mov        rdi, rax
0000000000272744         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272749         mov        dword [rbp+var_84], 0x24
0000000000272753         lea        rsi, qword [rbp+var_84]                     ; argument #2 for method sub_210992
000000000027275a         mov        rdi, rbx                                    ; argument #1 for method sub_210992
000000000027275d         call       sub_210992                                  ; sub_210992
0000000000272762         lea        rsi, qword [aPositioningAct]                ; "Positioning Active Cache"
0000000000272769         mov        rdi, rax
000000000027276c         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272771         mov        dword [rbp+var_88], 0x25
000000000027277b         lea        rsi, qword [rbp+var_88]                     ; argument #2 for method sub_210992
0000000000272782         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272785         call       sub_210992                                  ; sub_210992
000000000027278a         lea        rsi, qword [aOfflinePositio]                ; "Offline Positioning"
0000000000272791         mov        rdi, rax
0000000000272794         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272799         mov        dword [rbp+var_8C], 0x26
00000000002727a3         lea        rsi, qword [rbp+var_8C]                     ; argument #2 for method sub_210992
00000000002727aa         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002727ad         call       sub_210992                                  ; sub_210992
00000000002727b2         lea        rsi, qword [aIndoorPosition]                ; "Indoor Positioning"
00000000002727b9         mov        rdi, rax
00000000002727bc         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002727c1         mov        dword [rbp+var_90], 0x27
00000000002727cb         lea        rsi, qword [rbp+var_90]                     ; argument #2 for method sub_210992
00000000002727d2         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002727d5         call       sub_210992                                  ; sub_210992
00000000002727da         lea        rsi, qword [aIndoorVenuesPo]                ; "Indoor Venues Positioning"
00000000002727e1         mov        rdi, rax
00000000002727e4         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002727e9         mov        dword [rbp+var_94], 0x28
00000000002727f3         lea        rsi, qword [rbp+var_94]                     ; argument #2 for method sub_210992
00000000002727fa         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002727fd         call       sub_210992                                  ; sub_210992
0000000000272802         lea        rsi, qword [aPositioningCro]                ; "Positioning Crowdsourcing"
0000000000272809         mov        rdi, rax
000000000027280c         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272811         mov        dword [rbp+var_98], 0x29
000000000027281b         lea        rsi, qword [rbp+var_98]                     ; argument #2 for method sub_210992
0000000000272822         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272825         call       sub_210992                                  ; sub_210992
000000000027282a         lea        rsi, qword [aIndoorRouting]                 ; "Indoor Routing"
0000000000272831         mov        rdi, rax
0000000000272834         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272839         mov        dword [rbp+var_9C], 0x2a
0000000000272843         lea        rsi, qword [rbp+var_9C]                     ; argument #2 for method sub_210992
000000000027284a         mov        rdi, rbx                                    ; argument #1 for method sub_210992
000000000027284d         call       sub_210992                                  ; sub_210992
0000000000272852         lea        rsi, qword [aFleetConnectiv]                ; "Fleet Connectivity Extension"
0000000000272859         mov        rdi, rax
000000000027285c         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272861         mov        dword [rbp+var_A0], 0x2b
000000000027286b         lea        rsi, qword [rbp+var_A0]                     ; argument #2 for method sub_210992
0000000000272872         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272875         call       sub_210992                                  ; sub_210992
000000000027287a         lea        rsi, qword [aMapCustomizati]                ; "Map Customization"
0000000000272881         mov        rdi, rax
0000000000272884         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272889         mov        dword [rbp+var_A4], 0x2c
0000000000272893         lea        rsi, qword [rbp+var_A4]                     ; argument #2 for method sub_210992
000000000027289a         mov        rdi, rbx                                    ; argument #1 for method sub_210992
000000000027289d         call       sub_210992                                  ; sub_210992
00000000002728a2         lea        rsi, qword [aUrbanMobilityR]                ; "Urban Mobility Routing"
00000000002728a9         mov        rdi, rax
00000000002728ac         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002728b1         mov        dword [rbp+var_A8], 0x2d
00000000002728bb         lea        rsi, qword [rbp+var_A8]                     ; argument #2 for method sub_210992
00000000002728c2         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002728c5         call       sub_210992                                  ; sub_210992
00000000002728ca         lea        rsi, qword [aOfflineUrbanMo]                ; "Offline Urban Mobility Routing"
00000000002728d1         mov        rdi, rax
00000000002728d4         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002728d9         mov        dword [rbp+var_AC], 0x2e
00000000002728e3         lea        rsi, qword [rbp+var_AC]                     ; argument #2 for method sub_210992
00000000002728ea         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002728ed         call       sub_210992                                  ; sub_210992
00000000002728f2         lea        rsi, qword [aDepartureBoard]                ; "Departure Board"
00000000002728f9         mov        rdi, rax
00000000002728fc         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272901         mov        dword [rbp+var_B0], 0x2f
000000000027290b         lea        rsi, qword [rbp+var_B0]                     ; argument #2 for method sub_210992
0000000000272912         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272915         call       sub_210992                                  ; sub_210992
000000000027291a         lea        rsi, qword [aStationSearch]                 ; "Station Search"
0000000000272921         mov        rdi, rax
0000000000272924         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272929         mov        dword [rbp+var_B4], 0x30
0000000000272933         lea        rsi, qword [rbp+var_B4]                     ; argument #2 for method sub_210992
000000000027293a         mov        rdi, rbx                                    ; argument #1 for method sub_210992
000000000027293d         call       sub_210992                                  ; sub_210992
0000000000272942         lea        rsi, qword [aTransitCoverag]                ; "Transit Coverage"
0000000000272949         mov        rdi, rax
000000000027294c         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272951         mov        dword [rbp+var_B8], 0x31
000000000027295b         lea        rsi, qword [rbp+var_B8]                     ; argument #2 for method sub_210992
0000000000272962         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272965         call       sub_210992                                  ; sub_210992
000000000027296a         lea        rsi, qword [aPlatformDataEx]                ; "Platform Data Extension"
0000000000272971         mov        rdi, rax
0000000000272974         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272979         mov        dword [rbp+var_BC], 0x32
0000000000272983         lea        rsi, qword [rbp+var_BC]                     ; argument #2 for method sub_210992
000000000027298a         mov        rdi, rbx                                    ; argument #1 for method sub_210992
000000000027298d         call       sub_210992                                  ; sub_210992
0000000000272992         lea        rsi, qword [aOfflineTruckRo]                ; "Offline Truck Routing"
0000000000272999         mov        rdi, rax
000000000027299c         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002729a1         mov        dword [rbp+var_C0], 0x33
00000000002729ab         lea        rsi, qword [rbp+var_C0]                     ; argument #2 for method sub_210992
00000000002729b2         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002729b5         call       sub_210992                                  ; sub_210992
00000000002729ba         lea        rsi, qword [aTruckNavigatio]                ; "Truck Navigation"
00000000002729c1         mov        rdi, rax
00000000002729c4         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002729c9         mov        dword [rbp+var_C4], 0x34
00000000002729d3         lea        rsi, qword [rbp+var_C4]                     ; argument #2 for method sub_210992
00000000002729da         mov        rdi, rbx                                    ; argument #1 for method sub_210992
00000000002729dd         call       sub_210992                                  ; sub_210992
00000000002729e2         lea        rsi, qword [aAdvancedTruckN]                ; "Advanced Truck Navigation"
00000000002729e9         mov        rdi, rax
00000000002729ec         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
00000000002729f1         mov        dword [rbp+var_C8], 0x36
00000000002729fb         lea        rsi, qword [rbp+var_C8]                     ; argument #2 for method sub_210992
0000000000272a02         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272a05         call       sub_210992                                  ; sub_210992
0000000000272a0a         lea        rsi, qword [aOnlineBicycleR]                ; "Online Bicycle Routing"
0000000000272a11         mov        rdi, rax
0000000000272a14         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272a19         mov        dword [rbp+var_CC], 0x37
0000000000272a23         lea        rsi, qword [rbp+var_CC]                     ; argument #2 for method sub_210992
0000000000272a2a         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272a2d         call       sub_210992                                  ; sub_210992
0000000000272a32         lea        rsi, qword [aOfflineBicycle]                ; "Offline Bicycle Routing"
0000000000272a39         mov        rdi, rax
0000000000272a3c         call       imp___stubs___ZNSt3__112basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEE6assignEPKc ; std::__1::basic_string<char, std::__1::char_traits<char>, std::__1::allocator<char> >::assign(char const*)
0000000000272a41         mov        dword [rbp+var_D0], 0x38
0000000000272a4b         lea        rsi, qword [rbp+var_D0]                     ; argument #2 for method sub_210992
0000000000272a52         mov        rdi, rbx                                    ; argument #1 for method sub_210992
0000000000272a55         call       sub_210992                                  ; sub_210992
0000000000272a5a         lea        rsi, qword [aPrivate3dVenue]                ; "Private 3D Venue Maps"
0000000000272a61         mov        rdi, rax
0000000000272a64         add        rsp, 0xc8
0000000000272a6b         pop        rbx
0000000000272a6c         pop        rbp`

const HERE_PUBLIC_KEY =
`-----BEGIN CERTIFICATE-----
MIIF8TCCA9mgAwIBAgIJAMUgJzkQDe6wMA0GCSqGSIb3DQEBBQUAMIGOMQswCQYD
VQQGEwJDQTELMAkGA1UECAwCQkMxEjAQBgNVBAcMCVZhbmNvdXZlcjENMAsGA1UE
CgwESGVyZTEaMBgGA1UECwwRRXZlcnlkYXkgTW9iaWxpdHkxDTALBgNVBAMMBEhl
cmUxJDAiBgkqhkiG9w0BCQEWFWRhdmlkLmxlb25nQG5va2lhLmNvbTAeFw0xMzEy
MDIwMDMxNTlaFw0xNDAxMDEwMDMxNTlaMIGOMQswCQYDVQQGEwJDQTELMAkGA1UE
CAwCQkMxEjAQBgNVBAcMCVZhbmNvdXZlcjENMAsGA1UECgwESGVyZTEaMBgGA1UE
CwwRRXZlcnlkYXkgTW9iaWxpdHkxDTALBgNVBAMMBEhlcmUxJDAiBgkqhkiG9w0B
CQEWFWRhdmlkLmxlb25nQG5va2lhLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIP
ADCCAgoCggIBAJPZZNibIH8ULWd189iL5tKo5/1gVQX0KZBURGj6aY3BcvWIqQzM
5g6a7N6SmaqTOrSK3fmpcpiPjgRM9UlJI3WW/KjvvHLv/D09BbW90M48uj1/VlzH
EMti/n0X8s2TZL2pv78HBDoEJw3rPsl9guR8DDWVKrY5K9EUUPNy78AgW21pvZLb
nrb4LAnX3fFGPY8Ctrxx5ZlXo5N0Onj6ZejwrDU/PYM7+/YFI6Lzf1D8RpRHlOmq
Af3vtF3Go0pIIDLblNf3hVyA5/GfgGRCCHbb3W2dpIDT+2gaKkPWHLQxQY0SR+EP
ehBO+hJLaWRFl2zVSy6DtzP8QbW9c6paUA5UXepXB4lKny+mjrIOKRzJ02LWUk5d
oEpBnO5525i2cOPbSzPgpVGP2vPy7QGt/7JuWWvlVUoeVa9x9oGIRP825I087eRP
oKsX1cHzHvD+m4pz/2U6/CfSRiqCSGyjs7GFCgL8Nls08rrb4kterL3c/lzJRYE/
8Krs+p8YjS6oGhm+afFGDWKsHIa3l9A+le2xiYyW02ngr1QlLg889zlfDk4vHVjD
lLb3amnOUkJZrPU4XAGu0kTc3RTSDd6bprd22oFsI1jCLYnJ0ODEy/i4Y95yOYzz
tLRRH5h1RcRlEyhCWVvsvTP9FHSFzKkT6Yk6L/W/XS2Ts8EISyGO2dwtAgMBAAGj
UDBOMB0GA1UdDgQWBBSqN5ec3Te4voZJC9fhPiAdv0GeTDAfBgNVHSMEGDAWgBSq
N5ec3Te4voZJC9fhPiAdv0GeTDAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBBQUA
A4ICAQAVDA5/bbiUMjAoL5oIqBZPQozJYooyQo/EjUVCTwfdpS7trIe/olEExBAx
+j8+jaWEIOREoIc7xwx111n8Ij0yAUR9QQFQYGOoC67pp1O1B8xuPolwV5RcT2Xt
ndLgOCIPm8ZRdXn4fmjPj5p1oNl1H5jN8Z80RZRs+yp2Ph36oClYvrilIwNTN/gH
2wjgAECsFCvdhcvgxyKTmo6JnkKTMH3Vpdxu02493cMhRYR2PrX9d2GHU50VLgVZ
Y29zs+4JvaGENq7Z0OxmBGDXz0+c91qO+iUZrd3aj8R33VwVwq8hJ2rPi9sd75+F
BfV6Zl55NydSpGS68bNa3yhdaUrbHk8gUUJ4ae4oOtAt8+khQIQXjsz+nveeKetl
jYqKYODGrD/rQFsh+2JAEaFGMz20gG1FzUsFheemZsDpbS0rJ0I9c6dyZquCqmnz
IxQz+IZImOOxFP8Qc6U5k6kvYgfmLY06Mka/8jqx1Fr3yzXEaJV5beISOHTnPyY9
4KWkLq09U2IZJXCCWVJNgJZEAAd6wl8v1asgGFQ548IgTCdkQpTmPoTfW5SIBKCb
422Dh6FBeyBVfhmbjTkheQXWzB1/DNOafB2nVx61ZYi94OxLUfhf4sWpq/cWnfWd
xLhTnkmpAGkdO4OtzwieN4Hh0x0chU2bNYgNfrzEBlhWUAIISg==
-----END CERTIFICATE-----
-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAk9lk2JsgfxQtZ3Xz2Ivm
0qjn/WBVBfQpkFREaPppjcFy9YipDMzmDprs3pKZqpM6tIrd+alymI+OBEz1SUkj
dZb8qO+8cu/8PT0Ftb3Qzjy6PX9WXMcQy2L+fRfyzZNkvam/vwcEOgQnDes+yX2C
5HwMNZUqtjkr0RRQ83LvwCBbbWm9ktuetvgsCdfd8UY9jwK2vHHlmVejk3Q6ePpl
6PCsNT89gzv79gUjovN/UPxGlEeU6aoB/e+0XcajSkggMtuU1/eFXIDn8Z+AZEII
dtvdbZ2kgNP7aBoqQ9YctDFBjRJH4Q96EE76EktpZEWXbNVLLoO3M/xBtb1zqlpQ
DlRd6lcHiUqfL6aOsg4pHMnTYtZSTl2gSkGc7nnbmLZw49tLM+ClUY/a8/LtAa3/
sm5Za+VVSh5Vr3H2gYhE/zbkjTzt5E+gqxfVwfMe8P6binP/ZTr8J9JGKoJIbKOz
sYUKAvw2WzTyutviS16svdz+XMlFgT/wquz6nxiNLqgaGb5p8UYNYqwchreX0D6V
7bGJjJbTaeCvVCUuDzz3OV8OTi8dWMOUtvdqac5SQlms9ThcAa7SRNzdFNIN3pum
t3bagWwjWMIticnQ4MTL+Lhj3nI5jPO0tFEfmHVFxGUTKEJZW+y9M/0UdIXMqRPp
iTov9b9dLZOzwQhLIY7Z3C0CAwEAAQ==
-----END PUBLIC KEY-----`


function decryptHereKey(license) {
    if(license == null || license.length == 0) {
      return {}
    }

    var featureTableNoNewLines = FEATURE_TABLE.replace(/(\r\n|\n|\r)/gm, " ");
    var featuresArray = featureTableNoNewLines.match(/dword.+?0x(\w+).+?\"(.+?)\"/mg)
    var featuresObj = {}

    // get the features and their hex values from the feature table
    for(var s = 0; s < featuresArray.length; s++) {
      // get the hex number that corresponds to the feature
      var feature = featuresArray[s].match(/(?<=0x).+?(?=\ )/gm)
      var value = featuresArray[s].match(/(?<=").+?(?=")/gm)
      featuresObj[feature] = value[0]
    }

    const key = new NodeRSA(HERE_PUBLIC_KEY)
    var decodedLicense = license.toString('base64') //base 64 decode

    var decryptedLicense = key.decryptPublic(decodedLicense)
    var permissions_string = decryptedLicense.toString()

    var permissions_array = permissions_string.split(",")
    var permissions = {}

    for(var i = 0; i < permissions_array.length - 1; i+=2) {
      var k = permissions_array[i];
      var v = permissions_array[i+1];
      permissions[k] = v;
    }

    // converts a string of hex values to ascii values
    // converts pairs of to hex characters into their corresponding ascii
    function pack(string) {
      var result=""
      for(var i = 0; i < string.length; i+=2) {
        var hex = string.substring(i,i+2)
        var ascii = String.fromCharCode(parseInt(hex, 16))
        result += ascii
      }
      return result
    }

    function stringToDate(secs) {
      var t = new Date(1970, 0, 1); // Epoch
      t.setSeconds(secs);
      return t;
    }


    const EXPIRATION_DATE = "1001"
    var result = {}
    for (const [feature, value] of Object.entries(permissions)) {
      var int_value;
      if (["1002", EXPIRATION_DATE].includes(feature)) {
        var str = pack(value)
        if (EXPIRATION_DATE == feature){
          result["Expires"] = stringToDate(str)
        }
        else {
          result[feature] = str
        }
      }
      else if (['0', '1', '2'].includes(feature)){
        result[feature] = `SHA256 ${value}`
      }
      else if ((int_value = parseInt(value.match(/[0-9].+?/mg))) > 0) {
        result[featuresObj[feature] || feature] = int_value.toString(2)
      }
      else {
        result[feature] = value
      }
    }

    return result
}

export {
    selectModifiedValues,
    stripAuthHeaders,
    errorHandler,
    getBillingAccountByUUID,
    filterResources,
    decryptHereKey,
    handleEmpty
}
